<template>
  <v-simple-table class="warehouses-list">
    <template v-slot:default>
      <thead>
        <tr class="warehouses-list__row">
          <th class="text-left" v-text="'№'" />
          <th class="text-left" v-text="'Дата'" />
          <th class="text-left" v-text="'Источник'" />
          <th class="text-left" v-text="'Склад'" />
          <th class="text-left" v-text="'Количество товаров'" />
          <th class="text-left" v-text="'Обработано'" />
        </tr>
      </thead>
      <tbody>
        <tr class="warehouses-list__row warehouses-list__row--link" v-for="(item, index) in warehousesList" :key="item.id" @click="routeToWarehouse(item.id)">
          <td class="text-left text-caption" v-text="(page - 1) * itemsPerPage + index + 1" />
          <td class="text-left text-caption" v-text="lastSyncAt(item)" />
          <td class="text-left text-caption" v-text="get(item, 'owner.name')" />
          <td class="text-left text-caption" v-text="item.name" />
          <td class="text-left text-caption" v-text="item.total_items_count" />
          <td class="text-left text-caption" v-text="matchedPercent(item)" />
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import get from 'lodash.get';
import { mapState } from 'vuex';
import VueRouter from 'vue-router';

const { isNavigationFailure, NavigationFailureType } = VueRouter;

const DATE_OPTIONS = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
};

export default {
  props: {
    page: { type: Number, required: true },
    itemsPerPage: { type: Number, required: true },
  },
  computed: {
    ...mapState('warehouses', ['warehouses']),
    warehousesList() {
      return Object.values(this.warehouses);
    },
  },
  methods: {
    get(data, path, defaultValue = '—') {
      return get(data, path, defaultValue);
    },
    lastSyncAt(item) {
      if (!item.last_sync_at) return '–';
      const date = new Date(Date.parse(item.last_sync_at));
      return date.toLocaleString('ru', DATE_OPTIONS);
    },
    matchedPercent(item) {
      return `${Math.floor((item.bound_items_count / item.total_items_count) * 100) || 0}%`;
    },
    routeToWarehouse(id) {
      this.$router.push({ name: 'WarehouseItems', params: { warehouseId: id } })
        .catch((error) => {
          if (!isNavigationFailure(error, NavigationFailureType.cancelled)) {
            throw error;
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.warehouses-list {
  &__row {
    transition: all 0.1 ease-in-out;
    background-color: hsla(0, 0%, 98%, 1);

    &--link {
      cursor: pointer;

     &:hover {
        color: var(--v-primary-base);
      }
    }
  }
}
</style>
