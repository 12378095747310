<template>
  <v-container fluid>
    <v-col cols="12 sticky">
      <v-toolbar dense elevation="0">
        <v-toolbar-title>
          <div v-text="'Фиды поставщиков'" />
        </v-toolbar-title>
        <v-spacer />
        <QueryFilterObserver v-bind="filters.source" />
      </v-toolbar>
    </v-col>
    <v-col cols="12" class="my-15 text-center" v-if="isLoading">
      <v-progress-circular size="42" indeterminate />
    </v-col>
    <v-col cols="12" class="my-15 text-center" v-else-if="hasError">
      Что-то пошло не так, попробуйте обновить страницу или <router-link to="/">перейти на главную</router-link>
    </v-col>
    <v-col cols="12 pa-0" v-else>
      <WarehousesList :page="page" :items-per-page="itemsPerPage" />
    </v-col>
    <v-row class="my-5" justify="center" v-if="pageCount">
      <v-col cols="3">
        <v-pagination v-model="page" :length="pageCount" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';

import PaginationMixin from '@/mixins/PaginationMixin';

import QueryFilterObserver from '@/components/QueryFilterObserver';
import WarehousesList from '@/components/Warehouses/WarehousesList';

export default {
  components: {
    QueryFilterObserver,
    WarehousesList,
  },
  mixins: [PaginationMixin],
  data() {
    return {
      itemsPerPage: 24,
      isLoading: false,
      hasError: false,
    };
  },
  metaInfo() {
    return {
      title: 'Фиды поставщиков — Инвентарь',
    };
  },
  computed: {
    ...mapState('warehouses', ['warehouses', 'warehouses_count']),
    ...mapGetters('reference', ['ownersForSelect']),
    filters() {
      return {
        source: {
          component: 'v-autocomplete',
          name: 'owner__slug',
          attrs: {
            label: 'Источник',
            items: this.ownersForSelect,
            clearable: true,
            class: 'search-field',
            hideDetails: true,
            disabled: this.isLoading || this.hasError,
          },
        },
      };
    },
    pageCount() {
      return Math.ceil(this.warehouses_count / this.itemsPerPage);
    },
  },
  watch: {
    '$route.query': {
      immediate: true,
      deep: true,
      async handler(params) {
        this.isLoading = true;
        this.hasError = false;

        try {
          await this.FETCH_WAREHOUSES({ ...params, page_size: this.itemsPerPage });
        } catch (e) {
          this.hasError = true;
          if (!e.isAxiosError) throw e;
        } finally {
          this.isLoading = false;
        }
      },
    },
  },
  created() {
    this.FETCH_OWNERS({ page_size: 100500 });
  },
  methods: {
    ...mapActions('warehouses', ['FETCH_WAREHOUSES']),
    ...mapActions('reference', ['FETCH_OWNERS']),
  },
};
</script>

<style lang="scss" scoped>
.sticky {
  padding-bottom: 16px;
  position: sticky;
  top: 0;
  background-color: hsl(0, 0%, 100%);
  border-bottom: 1px solid hsla(0, 0%, 92%, 1);
  z-index: 5;
}

.search-field {
  max-width: 400px;
  font-size: 12px;
}

::v-deep .search-field .v-label {
  font-size: 12px;
}
</style>
